import {
	useCallback, useMemo, useRef, useState 
} from "react";

import { RecipeBoxPropsUnion } from "./RecipeBox";

export const useRecipeBox = ({
	recipe,
	unsuitableRecipe,
	allRecipeData,
	selectedRecipes,
	setSelectedRecipes,
	type = "account",
	large,
	unsuitable,
	tag,
	benefits = [],
	allergies,
	breed,
	healthIssues,
	link,
}: RecipeBoxPropsUnion) => {
	// TODO unsuitable recipes passes in an object instead of a string, need to refactor to not do that as it's confusing.
	const recipeData = useMemo(() => {
		return allRecipeData?.filter((recipeInfo) =>
			unsuitable
				? recipeInfo.handle === unsuitableRecipe.handle
				: recipeInfo.handle === recipe
		)[0];
	}, [
		allRecipeData,
		recipe,
		unsuitable,
		unsuitableRecipe?.handle
	]);

	const [modalOpen, setModalOpen] = useState(false);
	const [imageShowing, setImageShowing] = useState(0);

	const onModalClose = useCallback(() => {
		setModalOpen(false);
	}, [setModalOpen]);

	const changeImage = useCallback(
		(direction: string) => {
			if (direction === "left" && recipeData) {
				if (imageShowing === 0) {
					setImageShowing(recipeData.images.length - 1);
				} else {
					setImageShowing(imageShowing - 1);
				}
			} else {
				if (imageShowing === recipeData.images.length - 1) {
					setImageShowing(0);
				} else {
					setImageShowing(imageShowing + 1);
				}
			}
		},
		[imageShowing, recipeData]
	);

	const updateSelectedRecipes = useCallback(() => {
		if (!unsuitable && setSelectedRecipes) {
			setSelectedRecipes(recipeData);
		}
	}, [
		recipeData,
		setSelectedRecipes,
		unsuitable
	]);

	// UNSUITABLE REASONS
	const unsuitableReason = useMemo(() => {
		if (!recipeData) {
			return "";
		}

		if (unsuitable && !link && unsuitableRecipe.breeds.includes(breed || "")) {
			return breed + "s";
		}

		if (
			unsuitable &&
			unsuitableRecipe.allergens.length > 0 &&
			allergies &&
			allergies.length > 0
		) {
			const allergy = allergies.filter((allergy) => {
				return unsuitableRecipe.allergens.includes(allergy);
			})[0];
			
			return "dogs with a " + allergy + " allergy" || "";
		}

		if (
			unsuitable &&
			unsuitableRecipe.ailments.length > 0 &&
			healthIssues &&
			healthIssues.length > 0
		) {
			const ailmentReason = healthIssues.filter((issue) => {
				return unsuitableRecipe.ailments.includes(issue);
			})[0];
			
			return "dogs with " + ailmentReason;
		}

		return "";
	}, [
		recipeData,
		unsuitable,
		link,
		unsuitableRecipe?.breeds,
		unsuitableRecipe?.allergens,
		unsuitableRecipe?.ailments,
		breed,
		allergies,
		healthIssues,
	]);

	const recipeBoxContentProps = useMemo(
		() => ({
			...(unsuitable
				? { unsuitableRecipe,
					unsuitable }
				: { benefits,
					tag,
					recipe }),
		}),
		[
			recipe,
			benefits,
			tag,
			unsuitable,
			unsuitableRecipe
		]
	);

	return {
		recipeBoxContentProps,
		modalOpen,
		changeImage,
		recipeData,
		imageShowing,
		benefits,
		tag,
		onModalClose,
		updateSelectedRecipes,
		setModalOpen,
		unsuitableReason,
		selectedRecipes,
		large,
		type,
	};
};
